import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/mrpView/fields";

export const modalElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Номер доверенности",
    tooltip: "Номер доверенности",
    className: "info-modal__element_title",
    value: fields.POWER_ATTORNEY_NUMBER,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата доверенности",
    tooltip: "Дата доверенности",
    className: "info-modal__element",
    value: fields.CREATION_DATE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Срок действия",
    tooltip: "Срок действия",
    className: "info-modal__element",
    value: fields.EXPIRED_AT,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Статус проверки ЭП",
    tooltip: "Статус проверки ЭП",
    className: "info-modal__element_title",
    value: fields.EP_STATUS,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Статус проверки метаданных МЧД",
    tooltip: "Статус проверки метаданных МЧД",
    className: "info-modal__element_title",
    value: fields.EP_METADATA_STATUS,
    isShowContentTooltip: true,
  },
];

export const trusteeInn = [
  {
    id: uuid(),
    isEdited: false,
    title: "Наименование",
    tooltip: "Наименование доверителя",
    className: "info-modal__element_title",
    value: fields.TRUSTEE_COMPANY_NAME,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "ИНН",
    tooltip: "ИНН доверителя",
    className: "info-modal__element",
    value: fields.TRUSTEE_INN,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "ОГРН",
    tooltip: "ОГРН доверителя",
    className: "info-modal__element",
    value: fields.TRUSTEE_OGRN,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "ФИО лица, действующего без доверенности",
    tooltip: "ФИО лица, действующего без доверенности",
    className: "info-modal__element_title",
    value: fields.TRUSTEE_FIO,
  },
];

export const representativeElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "ФИО представителя",
    tooltip: "ФИО представителя",
    className: "info-modal__element_title",
    value: fields.REPRESENTATIVE_FIO,
  },
];

export const permissionsElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Полномочия представителя по доверенности",
    tooltip: "Полномочия представителя по доверенности",
    className: "info-modal__element_title",
    value: fields.PERMISSIONS,
    isShowContentTooltip: true,
  },
];

export const fullModalElements = [...modalElements, ...trusteeInn, ...representativeElements, ...permissionsElements];
