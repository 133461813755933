import { Module } from "vuex";
import { IRootState } from "@monorepo/inventory/src/store";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { checkExistLibrary, encodeQueryData, getFullPath, getQuery } from "@monorepo/utils/src/api/utils";
import { convertFiltersCustomToApi } from "@monorepo/inventory/src/views/MRPView/utils/convertFiltersToApi";
import { IMRPElement } from "@monorepo/inventory/src/views/MRPView/types/element";
import { getSelectedIds } from "@monorepo/utils/src/utils/getSelectedIds";
import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertFiltersToApi, IConvertFiltersToApiResponse } from "@monorepo/utils/src/api/convertFiltersToApi";
import { mutations as baseMutations } from "@monorepo/utils/src/store/modules/mutations";
import { actions as baseActions } from "@monorepo/utils/src/store/modules/actions";
import { getters as baseGetters } from "@monorepo/utils/src/store/modules/getters";
import { ISection, Sections } from "@monorepo/utils/src/types/cellsSections";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import axios from "axios";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";

interface ITableState {
  filters: ITableFiltersObject;
  totalLength: number;
  section?: Sections;
  data: IMRPElement[];
  sectionCells: ISection;
  isOpenFilters: boolean;
  autorefresh: boolean;
  searchTemplates: ISearchTemplate[];
  cells: string[];
  infiniteId: string;
  isTableLoading: boolean;
  isLoadingToggleFilters: boolean;
  isLoadingChangeAutorefresh: boolean;
  libraries: {
    permissions: string[];
  };
}

const defaultFilters = (): ITableFiltersObject => ({
  sort: {},
  openedId: null,
  isSelectAll: false,
  selectedIds: {},
  fieldFilters: {},
  initMessagesLength: 50,
});

const getDefaultState = (): ITableState => ({
  filters: defaultFilters(),
  infiniteId: new Date().toString(),
  sectionCells: {} as ISection,
  totalLength: 0,
  searchTemplates: [],
  cells: [],
  section: Sections.MRP,
  data: [],
  isOpenFilters: false,
  autorefresh: false,
  isTableLoading: false,
  isLoadingToggleFilters: false,
  isLoadingChangeAutorefresh: false,
  libraries: {
    permissions: [],
  },
});

const resultExportPath = (payload: "PDF" | "CSV" | "XLSX") => {
  // switch (payload) {
  //   case "CSV":
  //     return QUERY_PATH.GET_AK_CSV_LIST;
  //   case "XLSX":
  //     return QUERY_PATH.GET_AK_XLS_LIST;
  //   default:
  //     return QUERY_PATH.GET_AK_PDF_LIST;
  // }
  return "";
};

export const mrpView: Module<ITableState, IRootState> = {
  namespaced: true,
  state: getDefaultState,
  mutations: {
    ...baseMutations,
    addSort(state: ITableState, payload: Record<string, SORT_TYPE>) {
      state.filters.sort = { ...payload };
    },
    setFilters(state: ITableState, payload: ReturnType<typeof defaultFilters>) {
      state.filters = payload;
    },
    resetState(state: ITableState) {
      Object.assign(state, getDefaultState());
    },
    clearFilters(state: ITableState) {
      state.filters = defaultFilters();
    },
    addData(
      state: ITableState,
      payload: {
        data: IMRPElement[];
        totalLength: number;
        isReset: boolean;
      }
    ) {
      state.data = payload.isReset ? payload.data : state.data.concat(payload.data);
      state.totalLength = payload.totalLength;
    },
    setTableLoading(state: ITableState, payload: boolean) {
      state.isTableLoading = payload;
    },
    addPermissionsLib(state: ITableState, payload: string[]) {
      state.libraries.permissions = payload || [];
    },
  },
  actions: {
    ...baseActions,
    async getPermissions({ commit, state }) {
      checkExistLibrary(state.libraries.permissions, async () => {
        const { data } = await getQuery<string[]>(QUERY_PATH.GET_MRP_PERMISSIONS);
        commit("addPermissionsLib", data);
      });
    },
    async getEventList({ commit, state, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi);
      const {
        data,
        total: totalLength,
        error,
      } = await getQuery<IMRPElement[]>(QUERY_PATH.GET_MRP_LIST, params).finally(() => {
        commit("setTableLoading", false);
      });
      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0 });
        return { data: state.data || [], totalLength: totalLength || 0 };
      }
      return { data: null, error };
    },
    async getMRPElement(info, payload: string) {
      const { data } = await getQuery<IMRPElement>(`${QUERY_PATH.GET_MRP_LIST}/${payload}`);
      return data || {};
    },
    async refreshEventList({ commit, state }) {
      const params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi);
      const { data, total: totalLength } = await getQuery<IMRPElement[]>(QUERY_PATH.GET_MRP_LIST, { ...params, offset: 0 });

      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0, isReset: true });
      }
      return { data: null };
    },
    // async getExportData({ state, dispatch, commit }, payload: "CSV" | "PDF" | "XLSX") {
    //   let params: IConvertFiltersToApiResponse | { ids: string[] };
    //   const selectedKeys = state.filters.selectedIds ? getSelectedIds(state.filters.selectedIds) : [];
    //   if (selectedKeys.length && !state.filters.isSelectAll) {
    //     params = {
    //       ids: selectedKeys,
    //     };
    //   } else {
    //     params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi, true);
    //   }
    //   const queryParams = encodeQueryData(params);
    //   if (payload !== "PDF") {
    //     dispatch("app/openNewWindow", getFullPath(resultExportPath(payload), queryParams), {
    //       root: true,
    //     });
    //   } else {
    //     commit("app/setIsExportFileLoading", true, {
    //       root: true,
    //     });
    //     const { data } = await getQuery<void>(QUERY_PATH.GET_AK_PDF_LIST, params);
    //     if (!data) {
    //       commit("app/setIsExportFileLoading", false, {
    //         root: true,
    //       });
    //     }
    //   }
    // },
  },
  getters: {
    ...baseGetters,
    isTableLoading(state: ITableState) {
      return state.isTableLoading;
    },
    permissionsLibrary(state: ITableState) {
      return state.libraries.permissions;
    },
  },
};
