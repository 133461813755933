





















































































import { defineComponent } from "@vue/composition-api";
import CardModalInfoElement from "@monorepo/uikit/src/components/tableCardModal/CardModalInfoElement.vue";
import CardModalStatusChip from "@monorepo/uikit/src/components/tableCardModal/CardModalStatusChip.vue";
import { mapActions, mapGetters } from "vuex";
import { IMRPElement } from "@monorepo/inventory/src/views/MRPView/types/element";
import {
  modalElements,
  permissionsElements,
  representativeElements,
  trusteeInn,
} from "@monorepo/utils/src/variables/projectsData/mrpView/modalElements";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/mrpView/viewTitle";
import { fields } from "@monorepo/utils/src/variables/projectsData/mrpView/fields";
import { convertApiItemToUi } from "@monorepo/inventory/src/views/MRPView/utils/convertApiItemToUi";
import CardModalToggleViewSize from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleViewSize.vue";
import CardModalToggleTableItems from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleTableItems.vue";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import ExportBtn from "@monorepo/uikit/src/components/tableViews/ExportBtn.vue";
import { CardMode } from "@monorepo/utils/src/types/cardMode";
import { Route } from "vue-router";
import CardModalChapter from "@monorepo/uikit/src/components/tableCardModal/CardModalChapter.vue";
import useModalChangeByKeypress from "@monorepo/utils/src/composables/useModalChangeByKeypress";
import useUniqueLinkModal from "@monorepo/utils/src/composables/useUniqueLinkModal";
import { ModalType } from "@monorepo/utils/src/variables/modalType";

export default defineComponent({
  name: "MRPInfoModal",
  components: {
    CardModalInfoElement,
    CardModalStatusChip,
    CardModalToggleViewSize,
    CardModalToggleTableItems,
    ExportBtn,
    CardModalChapter,
  },
  props: {
    type: {
      type: String,
      default: ModalType.EDIT,
    },
  },
  data() {
    return {
      modalElements,
      trusteeInn,
      representativeElements,
      permissionsElements,
      viewUniqKey,
      openedPanels: [0, 1, 2, 3],
      fields,
      section: Sections.MRP,
      cardMode: CardMode,
      element: {} as ReturnType<typeof convertApiItemToUi>,
      isLoading: false,
    };
  },
  watch: {
    openedId: {
      immediate: true,
      async handler(
        this: {
          $route: Route;
          addQueryOpenedId: () => void;
          getCard: () => void;
          isLoading: boolean;
          isShowAnimation: boolean;
        },
        value
      ) {
        if (value) {
          this.isLoading = true;
          await this.addQueryOpenedId();
          this.getCard();
        }
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user", "isShowAnimation", "cardModeList"]),
    ...mapGetters("mrpView", ["data", "openedId"]),
    cardModeResult(): CardMode {
      return this.cardModeList[this.section as string] || CardMode.DEFAULT;
    },
    // isShowExport(): boolean {
    //   return isAuthorityExist(this.user, authorities.AK_EXPORT);
    // },
    // isShowDownloadBtn(): boolean {
    //   return isAuthorityExist(this.user, authorities.AK_DOWNLOAD);
    // },
    status(): string {
      return this.element?.status?.toString().toLowerCase() || "";
    },
    statusColor(): string {
      switch (this.status) {
        case "действующая":
          return "#00A459";
        case "просрочена":
          return "#fca41e";
        case "отозвана":
          return "#fcf62d";
        default:
          return "#D34039";
      }
    },
  },
  methods: {
    ...mapActions("app", ["openNewWindow"]),
    ...mapActions("mrpView", ["changeOpenedId", "getExportElementData", "getMRPElement"]),
    getCard() {
      this.getMRPElement(this.openedId)
        .then((data: IMRPElement) => {
          this.element = convertApiItemToUi(data || {});
        })
        .catch(console.error)
        .finally(() => {
          this.isLoading = false;
        });
    },
    // openDownload(id: string | number) {
    //   this.openNewWindow(`${getFullPath(QUERY_PATH.GET_HDUO_DOWNLOAD)}?id=${id}`);
    // },
    closeModal() {
      this.$emit("close");
    },
  },
  setup(props, context) {
    const { root } = context;
    useModalChangeByKeypress(root, "mrpView", Sections.MRP, props.type);
    const { addQueryOpenedId } = useUniqueLinkModal(root, "mrpView");

    return {
      addQueryOpenedId,
    };
  },
});
