import { v4 as uuid } from "uuid";
import { fields } from "./fields";
import { headerCheckboxObject } from "@monorepo/utils/src/variables/headerCheckboxValue";

export const tableHeaders = [
  headerCheckboxObject(),
  {
    text: "Номер доверенности",
    tooltip: "Номер доверенности",
    defaultWidth: 170,
    value: fields.POWER_ATTORNEY_NUMBER,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Дата доверенности",
    tooltip: "Дата доверенности",
    defaultWidth: 170,
    value: fields.CREATION_DATE,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Срок действия",
    tooltip: "Срок действия",
    defaultWidth: 170,
    value: fields.EXPIRED_AT,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "ИНН доверителя",
    tooltip: "ИНН доверителя",
    defaultWidth: 180,
    value: fields.TRUSTEE_INN,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "ФИО лица, действующего без доверенности",
    tooltip: "ФИО лица, действующего без доверенности",
    defaultWidth: 280,
    value: fields.TRUSTEE_FIO,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Наименование доверителя",
    tooltip: "Наименование доверителя",
    defaultWidth: 280,
    value: fields.TRUSTEE_COMPANY_NAME,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "ОГРН доверителя",
    tooltip: "ОГРН доверителя",
    defaultWidth: 190,
    value: fields.TRUSTEE_OGRN,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "ФИО представителя",
    tooltip: "ФИО представителя",
    defaultWidth: 280,
    value: fields.REPRESENTATIVE_FIO,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Идентификатор представителя",
    tooltip: "Идентификатор представителя",
    defaultWidth: 190,
    value: fields.REPRESENTATIVE_ID,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Полномочия представителя",
    tooltip: "Полномочия представителя по доверенности",
    defaultWidth: 320,
    value: fields.PERMISSIONS,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Статус",
    tooltip: "Статус",
    defaultWidth: 320,
    value: fields.STATUS,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
];
