import { fields } from "@monorepo/utils/src/variables/projectsData/mrpView/fields";

export const currentFilters = (): Record<string, unknown> => ({
  [fields.POWER_ATTORNEY_NUMBER]: "",
  [fields.FILTER_CREATION_DATE_FROM]: "",
  [fields.FILTER_CREATION_DATE_TO]: "",
  [fields.FILTER_EXPIRED_AT_FROM]: "",
  [fields.FILTER_EXPIRED_AT_TO]: "",
  [fields.TRUSTEE_INN]: "",
  [fields.TRUSTEE_FIO]: "",
  [fields.TRUSTEE_COMPANY_NAME]: "",
  [fields.TRUSTEE_OGRN]: "",
  [fields.REPRESENTATIVE_FIO]: "",
  [fields.REPRESENTATIVE_ID]: "",
});
