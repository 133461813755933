import { fields } from "./fields";

export const filtersElements = [
  {
    value: fields.POWER_ATTORNEY_NUMBER,
    title: "Номер доверенности",
    tooltip: "Ввод вручную",
    type: "text",
    width: 250,
  },
  {
    value: fields.CREATION_DATE,
    dateValues: [fields.FILTER_CREATION_DATE_FROM, fields.FILTER_CREATION_DATE_TO],
    title: "Дата доверенности",
    tooltip: "Дата доверенности - два календаря",
    type: "datePeriod",
    width: 226,
  },
  {
    value: fields.EXPIRED_AT,
    dateValues: [fields.FILTER_EXPIRED_AT_FROM, fields.FILTER_EXPIRED_AT_TO],
    title: "Срок действия",
    tooltip: "Срок действия - два календаря",
    type: "datePeriod",
    width: 226,
  },
  {
    value: fields.TRUSTEE_INN,
    title: "ИНН доверителя",
    tooltip: "Ввод вручную",
    type: "text",
    width: 250,
  },
  {
    value: fields.TRUSTEE_FIO,
    title: "ФИО лица, действующего без доверенности",
    tooltip: "Ввод вручную",
    type: "text",
    width: 250,
  },
  {
    value: fields.TRUSTEE_COMPANY_NAME,
    title: "Наименование доверителя",
    tooltip: "Ввод вручную",
    type: "text",
    width: 250,
  },
  {
    value: fields.TRUSTEE_OGRN,
    title: "ОГРН доверителя",
    tooltip: "Ввод вручную",
    type: "text",
    width: 250,
  },
  {
    value: fields.REPRESENTATIVE_FIO,
    title: "ФИО представителя",
    tooltip: "Ввод вручную",
    type: "text",
    width: 250,
  },
  {
    value: fields.REPRESENTATIVE_ID,
    title: "Идентификатор представителя",
    tooltip: "Ввод вручную",
    type: "text",
    width: 250,
  },
];
