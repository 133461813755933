export interface IMRPFields {
  ID: string;
  POWER_ATTORNEY_NUMBER: string;
  CREATION_DATE: string;
  EXPIRED_AT: string;
  TRUSTEE_INN: string;
  TRUSTEE_FIO: string;
  TRUSTEE_COMPANY_NAME: string;
  TRUSTEE_OGRN: string;
  REPRESENTATIVE_FIO: string;
  REPRESENTATIVE_ID: string;
  PERMISSIONS: string;
  STATUS: string;
  EP_STATUS: string;
  EP_METADATA_STATUS: string;

  FILTER_CREATION_DATE_FROM: string;
  FILTER_CREATION_DATE_TO: string;
  FILTER_EXPIRED_AT_FROM: string;
  FILTER_EXPIRED_AT_TO: string;
}

export const fields: IMRPFields = {
  ID: "id",
  POWER_ATTORNEY_NUMBER: "powerAttorneyNumber",
  CREATION_DATE: "creationDate",
  EXPIRED_AT: "expiredAt",
  TRUSTEE_INN: "trusteeInn",
  TRUSTEE_FIO: "trusteeFio",
  TRUSTEE_COMPANY_NAME: "trusteeCompanyName",
  TRUSTEE_OGRN: "trusteeOgrn",
  REPRESENTATIVE_FIO: "representativeFio",
  REPRESENTATIVE_ID: "representativeId",
  PERMISSIONS: "permissions",
  STATUS: "status",
  EP_STATUS: "validateSignStatus",
  EP_METADATA_STATUS: "validateMetadataStatus",

  FILTER_CREATION_DATE_FROM: "creationDateFrom",
  FILTER_CREATION_DATE_TO: "creationDateTo",
  FILTER_EXPIRED_AT_FROM: "expiredAtFrom",
  FILTER_EXPIRED_AT_TO: "expiredAtTo",
};
