import { IMRPElement } from "@monorepo/inventory/src/views/MRPView/types/element";
import { fields } from "@monorepo/utils/src/variables/projectsData/mrpView/fields";
import moment from "moment";

export const convertApiItemToUi = (element: IMRPElement): any => ({
  ...element,
  [fields.ID]: element.id,
  [fields.CREATION_DATE]: element.creationDate ? moment(element.creationDate).format("YYYY-MM-DD") : "",
  [fields.EXPIRED_AT]: element.expiredAt ? moment(element.expiredAt).format("YYYY-MM-DD") : "",
  [fields.PERMISSIONS]: element.permissionText || "",
  [fields.STATUS]: element.status?.title || "",
  [fields.EP_STATUS]: element.validateSignStatus?.title || "",
  [fields.EP_METADATA_STATUS]: element.validateMetadataStatus?.title || "",
});
